import type {FilledContentRelationshipField, FilledLinkToDocumentField, FilledLinkToWebField, LinkField, PrismicDocument} from '@prismicio/types';
import {LinkType} from '@prismicio/types';

function isArray(element: unknown): element is unknown[] {
    return Array.isArray(element);
}

function hasProperty<T extends string>(element: unknown, property: T): element is Record<T, unknown> {
    if (element === undefined || element === null) {
        return false;
    }
    return Object.prototype.hasOwnProperty.call(element, property);
}

function isFilled<T>(argument: T | undefined | null | ''): argument is T {
    return argument !== undefined && argument !== null && argument !== '';
}

function isPrismicLink(link: any): link is LinkField {
    return hasProperty(link, 'link_type') && link.link_type !== undefined;
}
function isFilledLink(link: LinkField): link is FilledLinkToDocumentField<string, string, never> | FilledLinkToWebField {
    return link.link_type !== LinkType.Any && hasProperty(link, 'url') && link.url !== null;
}

function isFilledWebLink(link: LinkField): link is FilledLinkToWebField {
    return link.link_type === LinkType.Web && isFilledLink(link);
}

function isFilledContentRelationshipField(link: LinkField): link is FilledContentRelationshipField {
    return link.link_type === LinkType.Document && 'id' in link && 'lang' in link;
}

function isFilledKeyText(field: any): field is string {
    return typeof field === 'string' && field !== '';
}

function isPrismicDocument(document: any): document is PrismicDocument {
    return (
        document.uid !== undefined &&
        document.first_publication_date !== null &&
        document.last_publication_date !== null &&
        hasProperty(document, 'linked_documents') &&
        Array.isArray(document.linked_documents)
    );
}

export const usePrismicTypeGuards = () => {
    return {
        isFilledKeyText,
        hasProperty,
        isArray,
        isFilled,
        isFilledLink,
        isPrismicLink,
        isFilledWebLink,
        isPrismicDocument,
        isFilledContentRelationshipField,
    };
};
